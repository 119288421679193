.dt-widget {

	@media (max-width: 768px) {
		max-width: 350px;
		margin: 0 auto 50px auto;

		@media (max-width: 768px) {
			margin-bottom: 25px;
		}
	}

	.widget-title {
		@include font-size(16);
		line-height: 1.2;
		text-transform: uppercase;
		font-weight: 700;
		color: $theme__color;
		margin-top: 0;
		margin-bottom: 50px;

		@media (max-width: 768px) {
			margin-bottom: 25px;
		}
	}
}


/*-----------------------------------------------
  ## About Us
  ---------------------------------------------*/
@import "about-us";

/*-----------------------------------------------
  ## Links
  ---------------------------------------------*/
@import "links";

/*-----------------------------------------------
  ## Newsletter
  ---------------------------------------------*/
@import "newsletter";
