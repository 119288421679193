#dt-about-us {
	background-image: url(../../../media/images/about-us-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 100px;
	padding-bottom: 100px;

	.section-title {
		margin-top: 0;
		margin-bottom: 35px;
		@include font-size(40);
		font-weight: 300;
		text-transform: uppercase;
		color: $theme__color;
	}

	.about-us-content {
		color: $text__color-deep;
		margin-bottom: 30px;
		@include font-size(16);
		@include line-height(26);
	}

	.row {
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 767px) {
			display: block;
		}

		> div {
			> img {
				text-align: center;
				float: none !important;
				margin: 0 auto;
			}
			&:first-child {
				margin-bottom: 35px;
			}
		}
	}
}
