.dt-download-and-preview {
	position: relative;
	display: table;
	margin: 0 auto;
	padding: 15px 0;

	> a {
		display: inline-block;
		background-color: #3ecf8e;
		padding: 20px 40px 20px 60px;
		color: #fff;
		font-weight: 700;
		text-transform: uppercase;
		margin: 5px;
		position: relative;
		border: 2px solid 1px;
		border-radius: 5px;

		.sprite {
			width: 2em;
			position: absolute;
			top: 15px;
			left: 20px;
		}

		> .ibadge {
			position: absolute;
			top: -18px;
			left: 50%;
			transform: translateX(-50%);
			background-color: #0e9458;
			font-size: 0.8em;
			line-height: 1em;
			padding: 3px 5px;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		&:hover,
		&:focus {
			opacity: 0.8;
		}
	}

	&.paid > a > .ibadge {
		background-color: #000;
	}

	&.sale > a > .ibadge {
		background-color: #f15656;
	}

	> .dl-link {

	}

	> .dl-prvw {
		background-color: #4d6df9;
	}
}
