.dt-widget.about-widget {

	.addr {
		margin-bottom: 25px;
	}

	.hotlines,
	.social-profiles {
		margin: 0 0 25px 0;
		padding: 0;

		li {
			list-style: none;
		}
	}

	.social-profiles {
		margin: 0;

		> li {
			display: inline-block;

			> a {
				padding: 5px;
				
				> span {
					width: 14px;
					height: 14px;
					fill: $theme__color;
				}

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}
