#dt-banner {
	background-color: $theme__color;
	background-image: url("../../../media/images/banner-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;

	> .banner-content {
		max-width: 860px;
		text-align: center;
		color: #fff;
		padding: 0 15px;

		> h2 {
			@include font-size(48);
			font-weight: 300;
			margin-top: 0;
			margin-bottom: 25px;

			@media (max-width: 700px) {
				@include font-size(36);
				margin-bottom: 15px;
			}
		}

		.banner-description {
			@include font-size(16);
			@include letter-spacing(50);
		}

		.banner-actions {
			margin-top: 65px;
			@media (max-width: 700px) {
				margin-top: 20px;
			}
			
			> a {
				margin: 8px;
			}
		}
	}
}
