#dt-call-to-action {
	background-color: $theme__color;
	background-image: url("../../../media/images/call-to-action-bg.jpg");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
	color: #fff;
	padding: 100px 0;

	.title-ready-to-start {
		@include font-size(30);
		line-height: 1;
		font-weight: 500;
	}

	.title-get-in-touch {
		@include font-size(36);
		line-height: 1;
		font-weight: 300;
	}

	.call-to-action-btn {
		float: right;
		margin-top: 30px;
		
		@media (max-width: 1024px ) {
			text-align: center;
		}

		@media (max-width: 768px) {
			float: none;
			text-align: left;
		}

		> a {
			margin: 10px;
		}
	}
}
