#dt-support {
	background-image: url("../../../media/images/support-center-bg.jpg");
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: left bottom;
	padding-top: 130px;
	padding-bottom: 130px;

	@media( max-width: 1024px ) {
		padding-top: 75px;
		padding-bottom: 75px;
	}

	.support-messages {
		text-align: center;

		.title-style-first {
			@include font-size(32);
			font-weight: 300;
			margin-bottom: 20px;
			color: $text__color-deep;
		}

		.title-style-second {
			@include font-size(36);
			line-height: 1;
			margin-bottom: 30px;
			font-weight: 700;
			color: $theme__color;
		}

		.title-support {
			margin-top: 0;
			margin-bottom: 25px;
			@include font-size(36);
			font-weight: 300;
			color: $theme__color;
		}

	}
}
