.dt-btn {
	background-color: $theme__color-alt;
	color: #fff;
	font-weight: 700;
	padding: 15px 30px;
	border-radius: 4px;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	@include letter-spacing(50);
	display: inline-block;
	line-height: 1;
	border: none;
	transition: all 0.15s ease-in-out;

	&:hover,
	&:focus {
		background-color: #fff;
		color: $theme__color;
		text-decoration: none;
		outline: none;
	}

	&:active {
		transform: scale(0.95);
	}

	&.alt {
		background-color: #fff;
		color: $theme__color;

		&:hover,
		&:focus {
			background-color: $theme__color-alt;
			color: #fff;
		}
	}
}
