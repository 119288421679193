#dt-testimonials {
	background-color: #000;
	background-image: url("../../../media/images/testimonials-bg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 150px 0;
	color: #fff;

	@media (max-width: 1024px) {
		padding: 75px 0;
	}

	a {
		color: #fff;
	}

	.testimonial-item {
		> .content {
			@include font-size(22);

			&:before {
				content: '';
				font-family: "Helvetica Neue", Helvetica,Arial,sans-serif;
				display: block;
				width: 50px;
				height: 50px;
				margin: 0 auto;
				background-image: url("../img/qoute-symbol.png");
				background-repeat: no-repeat;
				margin-bottom: 50px;

			}
		}

		> .meta {
			margin-top: 60px;
			font-style: italic;

			> .name {
				@include font-size(18);
				font-weight: 500;
				line-height: 1;
				color: $theme__color;
				text-transform: uppercase;
				margin-bottom: 10px;
			}
		}
	}

	.carousel {
		max-width: 750px;
		margin: 0 auto;
		text-align: center;

		.carousel-inner {
			.item {
				transition-property: opacity;
			}

			.item,
			.active.left,
			.active.right {
				opacity: 0;
			}

			.active,
			.next.left,
			.prev.right {
				opacity: 1;
			}

			.next,
			.prev,
			.active.left,
			.active.right {
				left: 0;
				transform: translate3d(0, 0, 0);
			}
		}
	}
}
