#dt-products {
	background-color: #f4f6fa;
	padding: 150px 0;

	@media (max-width: 1024px) {
		padding: 100px 0;
	}

	.section-title {
		@include font-size(40);
		font-weight: 300;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 85px;
		color: $text__color-deep;
	}
}

.product-item {
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);
	backface-visibility: hidden;
	transition: all 0.15s ease-in-out;

	@media (max-width: 768px) {
		max-width: 350px;
		margin: 0 auto 30px auto;
	}

	&:hover {
		transform: translateY(-5px);
	}

	> .item-thumbnail {
		position: relative;
		background-color: #000;
		overflow: hidden;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;

		> img {
			width: 100%;
			height: auto;
			backface-visibility: hidden;
			transition: opacity 0.3s ease-in-out;
		}

		> a {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, 100%);
			background-color: #fff;
			padding: 15px 30px;
			border-radius: 2px;
			text-transform: uppercase;
			font-weight: 700;
			line-height: 1;
			transition: all 0.3s ease-in-out;
			opacity: 0;

			&:hover {
				background-color: $theme__color-alt;
				color: #fff;
			}
		}

		&:hover {
			> img {
				opacity: 0.3;
			}

			> a {
				transform: translate(-50%, -50%);
				opacity: 1;
			}
		}
	}

	> .product-info {
		padding: 20px;

		> .product-title {
			@include font-size(18);
			font-weight: 700;
			text-transform: uppercase;
			margin-top: 0;

			> a {
				color: $text__color-deep;

				&:hover {
					color: $theme__color;
				}
			}
		}
	}
}
