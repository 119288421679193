.dt-widget.newsletter-widget {
	.newsletter-msg {
		margin-bottom: 30px;
	}

	form {
		input {
			display: block;
			width: 100%;
			border: none;
			border-radius: 4px;
			height: 35px;
			padding: 10px 15px;
			outline: none;
			border: 1px solid transparent;
			transition: all 0.15s ease-in-out;

			&:focus {
				border-color: $theme__color;
			}
		}

		.spam-hater {
			display: block;
			text-align: right;
			color: $theme__color;
			@include font-size(10);
			margin-top: 5px;
			margin-bottom: 10px;
		}

		.dt-btn {
			padding: 10px 25px;
			font-weight: 500;
		}
	}
}
