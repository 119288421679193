#dt-blog {

	.container {
		padding-top: 75px;
		padding-bottom: 30px;
		@media (max-width: 768px) {
			padding-top: 30px;
		}
	}

	article.hentry {
		padding-bottom: 15px;
		margin-bottom: 50px;
		border-bottom: 1px solid #e5f4f4;

		.entry-header {
			
			.entry-meta {
				color: #767676;
				text-transform: uppercase;
				@include font-size(12);
				font-weight: 700;
				letter-spacing: 0.035em;
				margin-bottom: 10px;

				> .edit-link,
				> .byline {
					margin-left: 1em;
				}
			}

			.entry-title {
				margin-top: 0;
				font-weight: 700;
				@include font-size(40);
				letter-spacing: 0.025em;
				line-height: 1.2em;
				margin-bottom: 30px;

				@media (max-width: 768px) {
					@include font-size(24);
				}
			}

		}

		.post-thumbnail {			
			margin-bottom: 30px;

			@media (min-width: 992px) {
				margin-right: -16.66666667%;
				margin-left: -16.66666667%;
			}

			> a {
				display: block;
			}
			img {
				max-width: 100%;
				height: auto;
			}
		}

		.entry-content {
			line-height: 1.5em;

			img {
				max-width: 100%;
				height: auto;
			}

			.more-link {
				margin-top: 15px;
				display: inline-block;
				padding: 5px 15px;
				background-color: #333;
				color: #fff;
				border-radius: 5px;
				transition: all 0.15s ease-in-out;

				&:hover {
					background-color: #000;

					&:after {
						padding-left: 15px;
					}
				}
			}

			> h2 {
				background-color: #f7f7f7;
				padding: 15px 0px;
				position: relative;
				font-weight: 700;

				&:before,
				&:after {
					content: '';
					position: absolute;
					width: 300%;
					height: 100%;
					top: 0;
					left: 100%;
					background-color: #f7f7f7;
				}

				&:before {
					right: 100%;
					left: auto;
				}
			}
		}

		.entry-footer {
			display: table;
			width: 100%;
			border-top: 1px solid #eee;
			margin-top: 50px;
			padding: 15px 0;

			
			@media (min-width: 768px) {
				.cat-tags-links {
					display: table-cell;
					vertical-align: middle;
					width: 100%;
				}
			}

			.cat-links,
			.tags-links {
				display: block;
				@include font-size(12);
				font-weight: 700;
				letter-spacing: 0.1818em;
				padding-left: 2.5em;
				position: relative;
				text-transform: uppercase;
				a {
					color: #333;
				}
				.sprite {
					width: 14px;
					height: 14px;
					color: #767676;
					left: 0;
					margin-right: 0.5em;
					position: absolute;
				}
			}

			.cat-links + .tags-links {
				margin-top: 1em;
			}

			.entry-footer .cat-links a,
			.entry-footer .tags-links 

			.entry-footer .cat-links .icon,
			.entry-footer .tags-links .icon {
				color: #767676;
				left: 0;
				margin-right: 0.5em;
				position: absolute;
				top: 2px;
			}

			.edit-link {
				display: inline-block;

				a.post-edit-link {
					background-color: #222;
					-webkit-border-radius: 2px;
					border-radius: 2px;
					-webkit-box-shadow: none;
					box-shadow: none;
					color: #fff;
					display: inline-block;
					@include font-size(14);
					font-weight: 700;
					margin-top: 2em;
					padding: 0.7em 2em;
					-webkit-transition: background-color 0.2s ease-in-out;
					transition: background-color 0.2s ease-in-out;
					white-space: nowrap;
					&:hover,
					&:focus {
						background-color: #767676;
					}
				}
			}

		}
	}
}

#blog-post-comments {
	margin-bottom: 20px;

	@media (min-width: 768px) {
		margin-bottom: 50px
	}
}
