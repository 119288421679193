/*!
  Theme Name: DT Landing
  Theme URI: http://www.decentthemes.com
  Author: Aminul Islam
  Author URI: http://aminul.net
  Description: A Beautiful landing page for decentthemes.com
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: dtlanding
  Tags: single-column, left-sidebar, custom-header
  */
/*-----------------------------------------------
   >>> TABLE OF CONTENTS:
   -----------------------------------------------
	# Base Rules
 	# Components
 		## Button
 		## Pagination
 		## Download and Preview Action
 	# Widgets
 		## About Us
 		## Links
 		## Newsletter
 	# Sections
 		## Header
		## Banner
		## About Us
		## Products
		## Testimonials
		## Support Center
		## Call To Action
		## Blog
		## Footer
   ---------------------------------------------*/
/*-----------------------------------------------
  # Base Rules
  ---------------------------------------------*/
body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	color: #333;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
}

a {
	color: #4c4c4c;
	text-decoration: none;
	transition: all .2s ease-in-out;
}

a:hover {
	color: #333;
	text-decoration: none;
}

.sprite {
	display: inline-block;
	fill: currentColor;
}

.sprite > svg {
	max-width: 100%;
	max-height: 100%;
}

/* Tables */
table {
	border-collapse: collapse;
	margin: 0 0 1.5em;
	width: 100%;
}

thead th {
	border-bottom: 2px solid #bbb;
	padding-bottom: 0.5em;
}

th {
	padding: 0.4em;
	text-align: left;
}

tr {
	border-bottom: 1px solid #eee;
}

td {
	padding: 0.4em;
}

th:first-child,
td:first-child {
	padding-left: 0;
}

th:last-child,
td:last-child {
	padding-right: 0;
}

/* Accessibility */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}

#content[tabindex="-1"]:focus {
	outline: 0;
}

/* Definition Lists */
dd {
	margin: 0 1.5em 1.5em;
}

/*-----------------------------------------------
  # Components
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Buttons
  ---------------------------------------------*/
.dt-btn {
	background-color: #3ecf8e;
	color: #fff;
	font-weight: 700;
	padding: 15px 30px;
	border-radius: 4px;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	letter-spacing: 0.05em;
	display: inline-block;
	line-height: 1;
	border: none;
	transition: all 0.15s ease-in-out;
}

.dt-btn:hover, .dt-btn:focus {
	background-color: #fff;
	color: #4d6df9;
	text-decoration: none;
	outline: none;
}

.dt-btn:active {
	transform: scale(0.95);
}

.dt-btn.alt {
	background-color: #fff;
	color: #4d6df9;
}

.dt-btn.alt:hover, .dt-btn.alt:focus {
	background-color: #3ecf8e;
	color: #fff;
}

/*-----------------------------------------------
  ## Pagination
  ---------------------------------------------*/
.navigation {
	margin-bottom: 15px;
	text-align: center;
	display: block;
}

.navigation.pagination > .nav-links > span,
.navigation.pagination > .nav-links > a {
	display: inline-block;
	padding: 5px 10px;
	border: 1px solid #ddd;
}

.navigation.pagination > .nav-links > span:hover,
.navigation.pagination > .nav-links > a:hover {
	background-color: #ddd;
}

/*-----------------------------------------------
  ## Download and Preview Action
  ---------------------------------------------*/
.dt-download-and-preview {
	position: relative;
	display: table;
	margin: 0 auto;
	padding: 15px 0;
}

.dt-download-and-preview > a {
	display: inline-block;
	background-color: #3ecf8e;
	padding: 20px 40px 20px 60px;
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
	margin: 5px;
	position: relative;
	border: 2px solid 1px;
	border-radius: 5px;
}

.dt-download-and-preview > a .sprite {
	width: 2em;
	position: absolute;
	top: 15px;
	left: 20px;
}

.dt-download-and-preview > a > .ibadge {
	position: absolute;
	top: -18px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #0e9458;
	font-size: 0.8em;
	line-height: 1em;
	padding: 3px 5px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.dt-download-and-preview > a:hover, .dt-download-and-preview > a:focus {
	opacity: 0.8;
}

.dt-download-and-preview.paid > a > .ibadge {
	background-color: #000;
}

.dt-download-and-preview.sale > a > .ibadge {
	background-color: #f15656;
}

.dt-download-and-preview > .dl-prvw {
	background-color: #4d6df9;
}

/*-----------------------------------------------
  # Widgets
  ---------------------------------------------*/
@media (max-width: 768px) {
	.dt-widget {
		max-width: 350px;
		margin: 0 auto 50px auto;
	}
}

@media (max-width: 768px) and (max-width: 768px) {
	.dt-widget {
		margin-bottom: 25px;
	}
}

.dt-widget .widget-title {
	font-size: 16px;
	font-size: 1em;
	line-height: 1.2;
	text-transform: uppercase;
	font-weight: 700;
	color: #4d6df9;
	margin-top: 0;
	margin-bottom: 50px;
}

@media (max-width: 768px) {
	.dt-widget .widget-title {
		margin-bottom: 25px;
	}
}

/*-----------------------------------------------
  ## About Us
  ---------------------------------------------*/
.dt-widget.about-widget .addr {
	margin-bottom: 25px;
}

.dt-widget.about-widget .hotlines,
.dt-widget.about-widget .social-profiles {
	margin: 0 0 25px 0;
	padding: 0;
}

.dt-widget.about-widget .hotlines li,
.dt-widget.about-widget .social-profiles li {
	list-style: none;
}

.dt-widget.about-widget .social-profiles {
	margin: 0;
}

.dt-widget.about-widget .social-profiles > li {
	display: inline-block;
}

.dt-widget.about-widget .social-profiles > li > a {
	padding: 5px;
}

.dt-widget.about-widget .social-profiles > li > a > span {
	width: 14px;
	height: 14px;
	fill: #4d6df9;
}

.dt-widget.about-widget .social-profiles > li > a:hover {
	opacity: 0.7;
}

/*-----------------------------------------------
  ## Links
  ---------------------------------------------*/
.dt-widget.widget_nav_menu ul,
.dt-widget.links-widget ul {
	margin: 0;
	padding: 0;
}

.dt-widget.widget_nav_menu ul > li,
.dt-widget.links-widget ul > li {
	list-style: none;
}

.dt-widget.widget_nav_menu ul > li > a,
.dt-widget.links-widget ul > li > a {
	color: #333;
	line-height: 2;
	display: block;
}

.dt-widget.widget_nav_menu ul > li > a:hover,
.dt-widget.links-widget ul > li > a:hover {
	color: #4d6df9;
}

/*-----------------------------------------------
  ## Newsletter
  ---------------------------------------------*/
.dt-widget.newsletter-widget .newsletter-msg {
	margin-bottom: 30px;
}

.dt-widget.newsletter-widget form input {
	display: block;
	width: 100%;
	border: none;
	border-radius: 4px;
	height: 35px;
	padding: 10px 15px;
	outline: none;
	border: 1px solid transparent;
	transition: all 0.15s ease-in-out;
}

.dt-widget.newsletter-widget form input:focus {
	border-color: #4d6df9;
}

.dt-widget.newsletter-widget form .spam-hater {
	display: block;
	text-align: right;
	color: #4d6df9;
	font-size: 10px;
	font-size: 0.625em;
	margin-top: 5px;
	margin-bottom: 10px;
}

.dt-widget.newsletter-widget form .dt-btn {
	padding: 10px 25px;
	font-weight: 500;
}

/*-----------------------------------------------
  # Sections
  ---------------------------------------------*/
/*-----------------------------------------------
  ## Header
  ---------------------------------------------*/
.navbar-inside #dt-header {
	padding-top: 65px;
}

#dt-header .navbar {
	background-color: transparent;
	border-bottom: none;
	margin-top: 30px;
	backface-visibility: hidden;
	transition: all 0.15s ease-in-out;
}

.navbar-inside #dt-header .navbar,
.navbar-scrolled #dt-header .navbar {
	background-color: #fff;
	margin-top: 0;
	padding: 5px 0px;
	box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
	#dt-header .navbar {
		margin-top: 0;
		padding: 5px 0;
	}
	#dt-header .navbar .navbar-collapse {
		background: #fff;
		box-shadow: inset 0 1px 0 rgba(77, 109, 249, 0.1);
	}
}

#dt-header .navbar-nav > li > a {
	color: #fff;
	font-size: 16px;
	font-size: 1em;
	letter-spacing: 0.05em;
	font-weight: 500;
	text-transform: uppercase;
}

@media (max-width: 767px) {
	#dt-header .navbar-nav > li > a {
		color: #4d6df9;
	}
}

.navbar-inside #dt-header .navbar-nav > li > a,
.navbar-scrolled #dt-header .navbar-nav > li > a {
	color: #4d6df9;
}

#dt-header .navbar-nav > li > a:hover, #dt-header .navbar-nav > li > a:focus {
	background-color: transparent;
	opacity: 0.8;
}

#dt-header .navbar-nav > li.open > a {
	background-color: transparent;
}

#dt-header .navbar-header > .navbar-toggle .icon-bar {
	background-color: #fff;
}

.navbar-inside #dt-header .navbar-header > .navbar-toggle .icon-bar,
.navbar-scrolled #dt-header .navbar-header > .navbar-toggle .icon-bar {
	background-color: #4d6df9;
}

#dt-header .navbar-header > .navbar-brand {
	position: relative;
	padding: 8px 15px;
}

#dt-header .navbar-header > .navbar-brand > img:last-child {
	position: absolute;
	top: 8px;
	left: 15px;
	opacity: 0;
}

.navbar-inside #dt-header .navbar-header > .navbar-brand > img:first-child,
.navbar-scrolled #dt-header .navbar-header > .navbar-brand > img:first-child {
	opacity: 0;
}

.navbar-inside #dt-header .navbar-header > .navbar-brand > img:last-child,
.navbar-scrolled #dt-header .navbar-header > .navbar-brand > img:last-child {
	opacity: 1;
}

/*-----------------------------------------------
  ## Banner
  ---------------------------------------------*/
#dt-banner {
	background-color: #4d6df9;
	background-image: url("../../../media/images/banner-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

#dt-banner > .banner-content {
	max-width: 860px;
	text-align: center;
	color: #fff;
	padding: 0 15px;
}

#dt-banner > .banner-content > h2 {
	font-size: 48px;
	font-size: 3em;
	font-weight: 300;
	margin-top: 0;
	margin-bottom: 25px;
}

@media (max-width: 700px) {
	#dt-banner > .banner-content > h2 {
		font-size: 36px;
		font-size: 2.25em;
		margin-bottom: 15px;
	}
}

#dt-banner > .banner-content .banner-description {
	font-size: 16px;
	font-size: 1em;
	letter-spacing: 0.05em;
}

#dt-banner > .banner-content .banner-actions {
	margin-top: 65px;
}

@media (max-width: 700px) {
	#dt-banner > .banner-content .banner-actions {
		margin-top: 20px;
	}
}

#dt-banner > .banner-content .banner-actions > a {
	margin: 8px;
}

/*-----------------------------------------------
  ## About us
  ---------------------------------------------*/
#dt-about-us {
	background-image: url(../../../media/images/about-us-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 100px;
	padding-bottom: 100px;
}

#dt-about-us .section-title {
	margin-top: 0;
	margin-bottom: 35px;
	font-size: 40px;
	font-size: 2.5em;
	font-weight: 300;
	text-transform: uppercase;
	color: #4d6df9;
}

#dt-about-us .about-us-content {
	color: #323851;
	margin-bottom: 30px;
	font-size: 16px;
	font-size: 1em;
	line-height: 26px;
	line-height: 1.85714em;
}

#dt-about-us .row {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 767px) {
	#dt-about-us .row {
		display: block;
	}
}

#dt-about-us .row > div > img {
	text-align: center;
	float: none !important;
	margin: 0 auto;
}

#dt-about-us .row > div:first-child {
	margin-bottom: 35px;
}

/*-----------------------------------------------
  ## Products
  ---------------------------------------------*/
#dt-products {
	background-color: #f4f6fa;
	padding: 150px 0;
}

@media (max-width: 1024px) {
	#dt-products {
		padding: 100px 0;
	}
}

#dt-products .section-title {
	font-size: 40px;
	font-size: 2.5em;
	font-weight: 300;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 85px;
	color: #323851;
}

.product-item {
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);
	backface-visibility: hidden;
	transition: all 0.15s ease-in-out;
}

@media (max-width: 768px) {
	.product-item {
		max-width: 350px;
		margin: 0 auto 30px auto;
	}
}

.product-item:hover {
	transform: translateY(-5px);
}

.product-item > .item-thumbnail {
	position: relative;
	background-color: #000;
	overflow: hidden;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.product-item > .item-thumbnail > img {
	width: 100%;
	height: auto;
	backface-visibility: hidden;
	transition: opacity 0.3s ease-in-out;
}

.product-item > .item-thumbnail > a {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, 100%);
	background-color: #fff;
	padding: 15px 30px;
	border-radius: 2px;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1;
	transition: all 0.3s ease-in-out;
	opacity: 0;
}

.product-item > .item-thumbnail > a:hover {
	background-color: #3ecf8e;
	color: #fff;
}

.product-item > .item-thumbnail:hover > img {
	opacity: 0.3;
}

.product-item > .item-thumbnail:hover > a {
	transform: translate(-50%, -50%);
	opacity: 1;
}

.product-item > .product-info {
	padding: 20px;
}

.product-item > .product-info > .product-title {
	font-size: 18px;
	font-size: 1.125em;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 0;
}

.product-item > .product-info > .product-title > a {
	color: #323851;
}

.product-item > .product-info > .product-title > a:hover {
	color: #4d6df9;
}

/*-----------------------------------------------
  ## Testimonials
  ---------------------------------------------*/
#dt-testimonials {
	background-color: #000;
	background-image: url("../../../media/images/testimonials-bg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 150px 0;
	color: #fff;
}

@media (max-width: 1024px) {
	#dt-testimonials {
		padding: 75px 0;
	}
}

#dt-testimonials a {
	color: #fff;
}

#dt-testimonials .testimonial-item > .content {
	font-size: 22px;
	font-size: 1.375em;
}

#dt-testimonials .testimonial-item > .content:before {
	content: '';
	font-family: "Helvetica Neue", Helvetica,Arial,sans-serif;
	display: block;
	width: 50px;
	height: 50px;
	margin: 0 auto;
	background-image: url("../img/qoute-symbol.png");
	background-repeat: no-repeat;
	margin-bottom: 50px;
}

#dt-testimonials .testimonial-item > .meta {
	margin-top: 60px;
	font-style: italic;
}

#dt-testimonials .testimonial-item > .meta > .name {
	font-size: 18px;
	font-size: 1.125em;
	font-weight: 500;
	line-height: 1;
	color: #4d6df9;
	text-transform: uppercase;
	margin-bottom: 10px;
}

#dt-testimonials .carousel {
	max-width: 750px;
	margin: 0 auto;
	text-align: center;
}

#dt-testimonials .carousel .carousel-inner .item {
	transition-property: opacity;
}

#dt-testimonials .carousel .carousel-inner .item,
#dt-testimonials .carousel .carousel-inner .active.left,
#dt-testimonials .carousel .carousel-inner .active.right {
	opacity: 0;
}

#dt-testimonials .carousel .carousel-inner .active,
#dt-testimonials .carousel .carousel-inner .next.left,
#dt-testimonials .carousel .carousel-inner .prev.right {
	opacity: 1;
}

#dt-testimonials .carousel .carousel-inner .next,
#dt-testimonials .carousel .carousel-inner .prev,
#dt-testimonials .carousel .carousel-inner .active.left,
#dt-testimonials .carousel .carousel-inner .active.right {
	left: 0;
	transform: translate3d(0, 0, 0);
}

/*-----------------------------------------------
  ## Support Center
  ---------------------------------------------*/
#dt-support {
	background-image: url("../../../media/images/support-center-bg.jpg");
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: left bottom;
	padding-top: 130px;
	padding-bottom: 130px;
}

@media (max-width: 1024px) {
	#dt-support {
		padding-top: 75px;
		padding-bottom: 75px;
	}
}

#dt-support .support-messages {
	text-align: center;
}

#dt-support .support-messages .title-style-first {
	font-size: 32px;
	font-size: 2em;
	font-weight: 300;
	margin-bottom: 20px;
	color: #323851;
}

#dt-support .support-messages .title-style-second {
	font-size: 36px;
	font-size: 2.25em;
	line-height: 1;
	margin-bottom: 30px;
	font-weight: 700;
	color: #4d6df9;
}

#dt-support .support-messages .title-support {
	margin-top: 0;
	margin-bottom: 25px;
	font-size: 36px;
	font-size: 2.25em;
	font-weight: 300;
	color: #4d6df9;
}

/*-----------------------------------------------
  ## Call To Action
  ---------------------------------------------*/
#dt-call-to-action {
	background-color: #4d6df9;
	background-image: url("../../../media/images/call-to-action-bg.jpg");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
	color: #fff;
	padding: 100px 0;
}

#dt-call-to-action .title-ready-to-start {
	font-size: 30px;
	font-size: 1.875em;
	line-height: 1;
	font-weight: 500;
}

#dt-call-to-action .title-get-in-touch {
	font-size: 36px;
	font-size: 2.25em;
	line-height: 1;
	font-weight: 300;
}

#dt-call-to-action .call-to-action-btn {
	float: right;
	margin-top: 30px;
}

@media (max-width: 1024px) {
	#dt-call-to-action .call-to-action-btn {
		text-align: center;
	}
}

@media (max-width: 768px) {
	#dt-call-to-action .call-to-action-btn {
		float: none;
		text-align: left;
	}
}

#dt-call-to-action .call-to-action-btn > a {
	margin: 10px;
}

/*-----------------------------------------------
  ## Blog
  ---------------------------------------------*/
#dt-blog .container {
	padding-top: 75px;
	padding-bottom: 30px;
}

@media (max-width: 768px) {
	#dt-blog .container {
		padding-top: 30px;
	}
}

#dt-blog article.hentry {
	padding-bottom: 15px;
	margin-bottom: 50px;
	border-bottom: 1px solid #e5f4f4;
}

#dt-blog article.hentry .entry-header .entry-meta {
	color: #767676;
	text-transform: uppercase;
	font-size: 12px;
	font-size: 0.75em;
	font-weight: 700;
	letter-spacing: 0.035em;
	margin-bottom: 10px;
}

#dt-blog article.hentry .entry-header .entry-meta > .edit-link,
#dt-blog article.hentry .entry-header .entry-meta > .byline {
	margin-left: 1em;
}

#dt-blog article.hentry .entry-header .entry-title {
	margin-top: 0;
	font-weight: 700;
	font-size: 40px;
	font-size: 2.5em;
	letter-spacing: 0.025em;
	line-height: 1.2em;
	margin-bottom: 30px;
}

@media (max-width: 768px) {
	#dt-blog article.hentry .entry-header .entry-title {
		font-size: 24px;
		font-size: 1.5em;
	}
}

#dt-blog article.hentry .post-thumbnail {
	margin-bottom: 30px;
}

@media (min-width: 992px) {
	#dt-blog article.hentry .post-thumbnail {
		margin-right: -16.66666667%;
		margin-left: -16.66666667%;
	}
}

#dt-blog article.hentry .post-thumbnail > a {
	display: block;
}

#dt-blog article.hentry .post-thumbnail img {
	max-width: 100%;
	height: auto;
}

#dt-blog article.hentry .entry-content {
	line-height: 1.5em;
}

#dt-blog article.hentry .entry-content img {
	max-width: 100%;
	height: auto;
}

#dt-blog article.hentry .entry-content .more-link {
	margin-top: 15px;
	display: inline-block;
	padding: 5px 15px;
	background-color: #333;
	color: #fff;
	border-radius: 5px;
	transition: all 0.15s ease-in-out;
}

#dt-blog article.hentry .entry-content .more-link:hover {
	background-color: #000;
}

#dt-blog article.hentry .entry-content .more-link:hover:after {
	padding-left: 15px;
}

#dt-blog article.hentry .entry-content > h2 {
	background-color: #f7f7f7;
	padding: 15px 0px;
	position: relative;
	font-weight: 700;
}

#dt-blog article.hentry .entry-content > h2:before, #dt-blog article.hentry .entry-content > h2:after {
	content: '';
	position: absolute;
	width: 300%;
	height: 100%;
	top: 0;
	left: 100%;
	background-color: #f7f7f7;
}

#dt-blog article.hentry .entry-content > h2:before {
	right: 100%;
	left: auto;
}

#dt-blog article.hentry .entry-footer {
	display: table;
	width: 100%;
	border-top: 1px solid #eee;
	margin-top: 50px;
	padding: 15px 0;
}

@media (min-width: 768px) {
	#dt-blog article.hentry .entry-footer .cat-tags-links {
		display: table-cell;
		vertical-align: middle;
		width: 100%;
	}
}

#dt-blog article.hentry .entry-footer .cat-links,
#dt-blog article.hentry .entry-footer .tags-links {
	display: block;
	font-size: 12px;
	font-size: 0.75em;
	font-weight: 700;
	letter-spacing: 0.1818em;
	padding-left: 2.5em;
	position: relative;
	text-transform: uppercase;
}

#dt-blog article.hentry .entry-footer .cat-links a,
#dt-blog article.hentry .entry-footer .tags-links a {
	color: #333;
}

#dt-blog article.hentry .entry-footer .cat-links .sprite,
#dt-blog article.hentry .entry-footer .tags-links .sprite {
	width: 14px;
	height: 14px;
	color: #767676;
	left: 0;
	margin-right: 0.5em;
	position: absolute;
}

#dt-blog article.hentry .entry-footer .cat-links + .tags-links {
	margin-top: 1em;
}

#dt-blog article.hentry .entry-footer .entry-footer .cat-links a,
#dt-blog article.hentry .entry-footer .entry-footer .tags-links
.entry-footer .cat-links .icon,
#dt-blog article.hentry .entry-footer .entry-footer .tags-links .icon {
	color: #767676;
	left: 0;
	margin-right: 0.5em;
	position: absolute;
	top: 2px;
}

#dt-blog article.hentry .entry-footer .edit-link {
	display: inline-block;
}

#dt-blog article.hentry .entry-footer .edit-link a.post-edit-link {
	background-color: #222;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #fff;
	display: inline-block;
	font-size: 14px;
	font-size: 0.875em;
	font-weight: 700;
	margin-top: 2em;
	padding: 0.7em 2em;
	-webkit-transition: background-color 0.2s ease-in-out;
	transition: background-color 0.2s ease-in-out;
	white-space: nowrap;
}

#dt-blog article.hentry .entry-footer .edit-link a.post-edit-link:hover, #dt-blog article.hentry .entry-footer .edit-link a.post-edit-link:focus {
	background-color: #767676;
}

#blog-post-comments {
	margin-bottom: 20px;
}

@media (min-width: 768px) {
	#blog-post-comments {
		margin-bottom: 50px;
	}
}

/*-----------------------------------------------
  ## Footer
  ---------------------------------------------*/
.footer-widgets-area {
	padding-top: 85px;
	padding-bottom: 50px;
	background-color: #f4f6fa;
}

@media (max-width: 1024px) {
	.footer-widgets-area {
		padding-top: 50px;
	}
}

@media (max-width: 768px) {
	.footer-widgets-area {
		padding-bottom: 25px;
	}
}

.footer-copyright-notice {
	background-color: #323851;
	text-align: center;
	padding: 15px 0;
	color: #fff;
}
