/*!
  Theme Name: DT Landing
  Theme URI: http://www.decentthemes.com
  Author: Aminul Islam
  Author URI: http://aminul.net
  Description: A Beautiful landing page for decentthemes.com
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: dtlanding
  Tags: single-column, left-sidebar, custom-header
  */

 /*-----------------------------------------------
   >>> TABLE OF CONTENTS:
   -----------------------------------------------
	# Base Rules
 	# Components
 		## Button
 		## Pagination
 		## Download and Preview Action
 	# Widgets
 		## About Us
 		## Links
 		## Newsletter
 	# Sections
 		## Header
		## Banner
		## About Us
		## Products
		## Testimonials
		## Support Center
		## Call To Action
		## Blog
		## Footer
   ---------------------------------------------*/
// Variables
@import "variables/variables";

// Mixins
@import "mixins/mixins";

/*-----------------------------------------------
  # Base Rules
  ---------------------------------------------*/
@import "base/base";

/*-----------------------------------------------
  # Components
  ---------------------------------------------*/
@import "components/components";

/*-----------------------------------------------
  # Widgets
  ---------------------------------------------*/
@import "widgets/widgets";

/*-----------------------------------------------
  # Sections
  ---------------------------------------------*/
@import "sections/sections";
