#dt-header {
	.navbar-inside & {
		padding-top: 65px;
	}

	.navbar {
		background-color: transparent;
		border-bottom: none;
		margin-top: 30px;
		backface-visibility: hidden;
		transition: all 0.15s ease-in-out;

		.navbar-inside &,
		.navbar-scrolled & {
			background-color: #fff;
			margin-top: 0;
			padding: 5px 0px;
			box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
		}

		@media (max-width: 767px) {
			margin-top: 0;
			padding: 5px 0;

			.navbar-collapse {
				background: #fff;
				box-shadow: inset 0 1px 0 rgba($theme__color,0.1);
			}
		}
	}

	.navbar-nav {
		> li { 
			> a {
				color: #fff;
				@include font-size(16);
				@include letter-spacing(50);
				font-weight: 500;
				text-transform: uppercase;

				@media (max-width: 767px) {
					color: $theme__color;
				}

				.navbar-inside &,
				.navbar-scrolled & {
					color: $theme__color;
				}

				&:hover,
				&:focus {
					background-color: transparent;
					opacity: 0.8;
				}
			}

			&.open > a {
				background-color: transparent;
			}
		}
	}

	.navbar-header {

		> .navbar-toggle {
			.icon-bar {
				background-color: #fff;

				.navbar-inside &,
				.navbar-scrolled & {
					background-color: $theme__color;
				}
			}
		}
		> .navbar-brand {
			position: relative;
			padding: 8px 15px;

			> img {

				&:last-child {
					position: absolute;
					top: 8px;
					left: 15px;
					opacity: 0;
				}

				.navbar-inside &,
				.navbar-scrolled & {
					&:first-child {
						opacity: 0;
					}
					&:last-child {
						opacity: 1;
					}
				}
			}
		}
	}
}
