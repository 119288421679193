.dt-widget.widget_nav_menu,
.dt-widget.links-widget {

	ul {
		margin: 0;
		padding: 0;

		> li {
			list-style: none;

			> a {
				color: $text__color;
				line-height: 2;
				display: block;

				&:hover {
					color: $theme__color;
				}
			}
		}
	}
}
