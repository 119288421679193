.navigation {
	margin-bottom: 15px;
	text-align: center;
	display: block;

	&.pagination {
		
		> .nav-links {
			> span,
			> a {
				display: inline-block;
				padding: 5px 10px;
				border: 1px solid #ddd;

				&:hover {
					background-color: #ddd;
				}
			}
		}
	}
}
