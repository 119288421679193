#dt-footer {
	
}
.footer-widgets-area {
	padding-top: 85px;
	padding-bottom: 50px;
	background-color: #f4f6fa;

	@media (max-width: 1024px) {
		padding-top: 50px;
	}

	@media (max-width: 768px) {
		padding-bottom: 25px;
	}
}

.footer-copyright-notice {
	background-color: $text__color-deep;
	text-align: center;
	padding: 15px 0;
	color: #fff;
}
