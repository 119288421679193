body {
	@include font-smoothing; // <- _mixins.scss
	color: $text__color;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
}

a {
	color: #4c4c4c;
	text-decoration: none;
	transition: all .2s ease-in-out;

	&:hover {
		color: #333;
		text-decoration: none;
	}
}

.sprite {
	display: inline-block;
	fill: currentColor;

	> svg {
		max-width: 100%;
		max-height: 100%;
	}
}

/* Tables */
@import "table";

/* Accessibility */
@import "accessibility";

/* Definition Lists */
dt {}

dd {
	margin: 0 1.5em 1.5em;
}

